import axios from 'axios';
import { WS_URI } from './commonHelpers';
import { toast } from 'react-toastify';
import md5 from 'md5';

export const loginService = (user, password) => {
	 const md5pass = md5(password);
	return axios
		.get(`${WS_URI}/proveedores/login?user=${user}&password=${md5pass}`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error conectando al servicio o usuario incorrecto.`);
		});
};

export const getDocsPendientesService = empresa =>{
	return axios
		.get(`${WS_URI}/getDocumentosFaltanEmpresa?prov=${empresa}`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error obteniendo documentos.`);
		});
}

export const getDocsPendientesTrabajadoresService= empresa =>{
	return axios
		.get(`${WS_URI}/getDocumentosFaltanTrabajadoresByEmpresa?prov=${empresa}`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error obteniendo documentos.`);
		});
}

export const getDocumentosEmpresaObligatoriosService = (empresa) => {
	return axios
		.get(`${WS_URI}/getDocumentosEmpresaObligatorios?prov=${empresa}`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error obteniendo documentos.`);
		});
};

export const getDocsPendientesValidarService = empresa =>{
	return axios
		.get(`${WS_URI}/getDocumentosValidarEmpresa?prov=${empresa}`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error obteniendo documentos.`);
		});
}

export const getDocsPendientesValidarTrabajadorService = (empresa) => {
	return axios
		.get(`${WS_URI}/getDocumentosValidarTrabajador?trab=${empresa}`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error obteniendo documentos.`);
		});
};

 export const getDocumentoService = id=>{
	return axios.get(`${WS_URI}/getDocumentoByID?documento=${id}`).then((response) => response?.data);
}

export const getPersonalEmpresaService = (empresa) => {
	return axios
		.get(`${WS_URI}/getPersonalObraByProveedor?proveedor=${empresa}`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error obteniendo personal.`);
		});
};

export const getTiposDocumentosService = ()=>{
	return axios
		.get(`${WS_URI}/getTipoDocumentos`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error obteniendo personal.`);
		});
}
export const getDocumentosProveedorService = id=>{
	return axios
		.get(`${WS_URI}/getDocumentosEmpresa?prov=${id}`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error obteniendo personal.`);
		});
}

export const getDocumentosTrabajadorService = id=>{
	return axios
		.get(`${WS_URI}/getDocumentosPersona?persona=${id}`)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error obteniendo personal.`);
		});
}

export const subeDocumentoEmpresaService = documento=>{
	return axios
		.post(`${WS_URI}/subeDocumentoFichero`, documento)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error subiendo documentos.`);
		});
}


export const creaPersonaService = (persona) => {
	return axios
		.post(`${WS_URI}/savePersonal`, persona)
		.then((response) => response?.data)
		.catch((error) => {
			toast.error(`Error guardando personal.`);
			throw new Error('Error guardando personal');
		});
};

export const refreshTokenService = (user) => {
	return axios.get(`${WS_URI}/proveedores/refreshToken?user=${user}`).then((response) => response?.data);
};
