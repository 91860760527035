import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import PropTypes from "prop-types";
import PdfViewer from "./PdfViewer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDocumentosFetch } from "src/hooks/useDocumentosFetch";

const PreviewFileDialog = ({ id, setOpened, tipo, base64 }) => {
    const { documento, isLoadingDocumento } = useDocumentosFetch({ id });


	const onClose = useCallback(() => {
		setOpened(false);
	}, [setOpened]);

	const [isPdf, setIsPdf] = useState(false);
	const [rotate, setRotate] = useState(0);

	useEffect(() => {
		setIsPdf(false);
	}, [id]);

	const errorOnImage = useCallback(() => {
		setIsPdf(true);
	}, []);

	const rotar = useCallback(() => {
		setRotate(rotate + 90);
	}, [rotate]);

    const data = useMemo(()=>{
        if (base64) {
            return base64;
        }
        else{
            return documento?.documentoBase64;
        }
    },[base64, documento?.documentoBase64]);

    const isLoading = useMemo(()=>{
        if (base64) {
            return false;
        }
        return isLoadingDocumento;
    },[base64, isLoadingDocumento]);

	return (
		<Dialog
			header={`Ver documento ${tipo}`}
			visible={true}
			style={{ width: '80vw', minHeight: '80vh' }}
			onHide={onClose}

			//footer={footerContent}
		>
			<div className='col-12 p-2 ' style={{ height: '75vh' }}>
				{isLoading && <ProgressSpinner />}
				{isPdf && data && !isLoading && (
					// <embed
					// 	src={`data:application/pdf;base64,${documento?.documentoBase64}`}
					// 	type='application/pdf'
					// 	height='100%'
					// 	width='100%'
					// ></embed>
					<PdfViewer base64={`data:application/pdf;base64,${data}`} />
				)}
				{!isPdf && !isLoading && (
					<>
						<Button onClick={rotar} icon='pi pi-refresh' style={{ marginLeft: '50%' }} /> <br />
						<Image
							src={`data:image/jpeg;base64,${data}`}
							alt='Image'
							width='100%'
							style={{ transform: `rotate(${rotate}deg)`, marginLeft: '25%' }}
							onError={errorOnImage}
							preview
						/>
					</>
				)}
				{!isLoading && !data && <Message text='Sin imagen' className='w-full' />}
			</div>
		</Dialog>
	);
};

PreviewFileDialog.propTypes = {
    id: PropTypes.string,
    setOpened: PropTypes.func,
    tipo: PropTypes.string,
    base64: PropTypes.string
};

export default PreviewFileDialog;