import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useCallback, useMemo } from 'react';
import DownloadFileButton from './DownloadFileButton';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { useTiposDocFetch } from 'src/hooks/useTiposDocFetch';
import { Chip } from 'primereact/chip';
import PreviewFileButton from './PreviewFileButton';
import { orderBy } from 'lodash';
const TablaDocumentosPendientes = ({docsPendientes, setOpenedUpload, habilitado})=>{
	const { tipoDocs } = useTiposDocFetch();
    const renderFechaRow = useCallback((row, col) => {
        const value = row[col.field] ? new Date(row[col.field]) : null;
        return `${value ? value?.toLocaleDateString('es-ES') : ''}  `;
    }, []);

	const renderFecha = useCallback((value) => {
		return `${value ? new Date(value)?.toLocaleDateString('es-ES') : ''}  `;
	}, []);


	const datos = useMemo(
		() =>
			orderBy(docsPendientes?.map((d) => ({
				 ...d, 
				 tipoNombre: tipoDocs?.find((t) => t.id === d.tipo)?.tipo, 
				fechaCreacion : d.fechaCreacion ? new Date(d.fechaCreacion):null 
			})), 'fechaCreacion', 'desc'),
		[docsPendientes, tipoDocs]
	);

    const renderAccionesDocs = useCallback(
			(row) => {
				return (
					<>
						{habilitado && row?.documento?.documentoBase64 && (
							<DownloadFileButton base64Data={row?.documento?.documentoBase64} fileName={row?.documento?.nombre} />
						)}
						{habilitado && row?.documentoBase64 && (
							<DownloadFileButton base64Data={row?.documentoBase64} fileName={row?.nombre} />
						)}
						{row?.documento?.documentoBase64 && (
							<PreviewFileButton base64={row?.documento?.documentoBase64} tipo={row?.tipoNombre} />
						)}
						{row?.documentoBase64 && (
							<PreviewFileButton base64={row?.documentoBase64} tipo={row?.tipoNombre} />
						)}
						{habilitado && row?.rechazado && (
							<Button icon='pi pi-upload' rounded outlined onClick={() => setOpenedUpload(row)} />
						)}
					</>
				);
			},
			[habilitado, setOpenedUpload]
		);
	const rowClassName = useCallback((row) => {
		if (row?.rechazado) {
			return 'text-red-300';
		}
		if (row?.validado) {
			return 'text-green-800';
		}
		return 'text-yellow-800';
	}, []);
	const renderEstado = useCallback((row) => {
		if (row?.rechazado) {
			return <Chip label={`Rechazado ${renderFecha(row.rechazadoFecha)} `} className='bg-red-200' />;
		}
		if (row?.validado) {
			return <Chip label={`Validado ${renderFecha(row.validadoFecha)} `} className='bg-green-200' />;
		}
		return <Chip label={`Pendiente`} className='bg-yellow-200' />;
	}, [renderFecha]);

    return (
			<DataTable value={datos} stripedRows tableStyle={{ minWidth: '50rem' }} size='small' rowClassName={rowClassName}>
				<Column field='tipoNombre' header='Documento'></Column>
				<Column field='nombre' header='Nombre fichero'></Column>
				<Column field='fechaCreacion' header='Fecha Envío' body={renderFechaRow}></Column>
				<Column field='fecha' header='Fecha Documento' body={renderFechaRow}></Column>
				{/* <Column field='validado' header='Validado' body={renderSINO}></Column>
				<Column field='validadoFecha' header='Fecha validac' body={renderFecha}></Column>
				<Column field='rechazado' header='Rechazado' body={renderSINO}></Column>
				<Column field='rechazadoFecha' header='Fecha rec' body={renderFecha}></Column> */}
				<Column header='Estado' body={renderEstado}></Column>
				<Column field='rechazadoMotivo' header='Observaciones'></Column>
				<Column header='Acciones' body={renderAccionesDocs} width='200px'></Column>
			</DataTable>
		);
}

TablaDocumentosPendientes.propTypes = {
	docsPendientes: PropTypes.array,
	setOpenedUpload: PropTypes.func,
	habilitado: PropTypes.func
};
export default TablaDocumentosPendientes;