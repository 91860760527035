import { Button } from "primereact/button";
import PropTypes from "prop-types";
import { useState } from "react";
import PreviewFileDialog from "./PreviewFiledialog";

const PreviewFileButton = ({ id, tipo, base64 }) => {
	const [opened, setOpened] = useState(false);
    console.log('id', id);
    return (
			<>
				<Button
					rounded
					outlined
					onClick={() => setOpened(true)}
					icon='pi pi-file'
					tooltip='Previsualizar'
					className='p-button-rounded p-button-text'
				/>
				{opened && <PreviewFileDialog id={id} setOpened={setOpened} tipo={tipo} base64={base64}/>}
			</>
		);
};

export default PreviewFileButton;

PreviewFileButton.propTypes = {
    id: PropTypes.number, 
    tipo: PropTypes.string
};

