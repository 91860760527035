import { useContext } from "react";
import { useLocalStorage } from "./useLocalStorage";
import AppContext from "src/contexts/AppContext";
import { loginService } from "src/services/reactivaService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useQueryClient } from '@tanstack/react-query';

export const useAuthUser = () => {
	const { setItem } = useLocalStorage();
	const {user, setUser} = useContext(AppContext);
	
	 const queryClient = useQueryClient();
	
	const navigate = useNavigate();

	const login = (user,password) => {
		loginService(user, password).then((data) => {
			if (!data?.cif) {
				toast.error('¡Usuario Incorrecto!');
			} else {
				setItem('user', JSON.stringify(data));
				setUser(data);
				toast.error(`Bienvenido ${data.nombre}!`);
				navigate('/');
			}
		});
		
	};

	const logout = () => {
		setItem('user', null);
		setUser(null);
		 queryClient.clear();
	};

	return { user, login, logout };
};
