import { getDocumentoService } from 'src/services/reactivaService';
import {  useQuery } from '@tanstack/react-query';

export const useDocumentosFetch= ({id})=>{
    const { data: documento, isLoading: isLoadingDocumento } = useQuery({
			queryKey: ['documento', id],
			queryFn: async () => await getDocumentoService(id),
			enabled: !!id,
		});
    return {documento, isLoadingDocumento};
}
